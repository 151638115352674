import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';

import { orderURL } from '@portal/config/routes';
import { Order } from '@portal/components/orders/index';
import { Box, Button, COLORS, mq, SERIF_FONT_FAMILY, Text } from '@clutter/clean';

import { formatDate } from '@shared/utils';

import MAP_MARKER from '@portal/images/map_marker.svg';
import {
  Account__CancelIntent__Reason as AccountCancelIntentReason,
  OrderServiceTypeEnum,
  OrderStatusEnum,
  OrderTypeEnum,
} from '@portal/schema';
import { orderDisplayName } from '@portal/utils/order';
import { OrderDetailsOrder } from '@portal/components/orders/cancel/steps';
import { Spacer } from '@shared/components/helpers';
import { dateDetailsMap } from '@portal/utils/scheduling';
import { Badge } from './badge';
import { WaitlistRequests } from './waitlist_requests';
import { MultiDayTimeline } from '../home/appointment_details/info_list/multi_day_timeline';

const FROM_ISO_OPTIONS = { setZone: true };

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ButtonLink = styled(Link)`
  ${mq({
    width: ['100%', 'calc(50% - 8px)'],
    marginBottom: ['16px', 0],
  })}
  &:hover {
    text-decoration: none;
  }
`;

const FullWidthButtonLink = styled(Link)`
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

const CancelLink = styled(Link)`
  color: ${COLORS.toucan};
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  ${mq({
    marginTop: [0, '32px'],
  })}
  &:hover {
    text-decoration: none;
    color: ${COLORS.toucan};
  }
`;

const DisabledButton = styled(Text.Button)`
  color: ${COLORS.hippo};
`;

const Container = styled.div`
  background: ${COLORS.cloud};
  border: 1.5px solid ${COLORS.grayBorder};
  ${mq({
    width: [null, '600px'],
    padding: ['24px', null, '24px 36px'],
    borderRadius: [0, 0, '4px'],
    borderLeftWidth: [0, '1px'],
    borderRightWidth: [0, '1px'],
  })}
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
`;

const Date = styled.div`
  font-family: ${SERIF_FONT_FAMILY};
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
`;

const Address = styled.div`
  font-weight: 600;
  line-height: 160%;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
`;

const ShipmentCardBodyContainer = styled.div`
  margin-bottom: 32px;
`;

export const RetailDeliveryCard = ({ order, editable }: ICardProps) => {
  const scheduled = DateTime.fromISO(order.scheduled, FROM_ISO_OPTIONS);
  const { status, address, id } = order;

  const orderType = orderDisplayName(order);

  return (
    <Container>
      <Labels>
        <Badge text={orderType} kind="primary" />
        <Badge text={status} kind={status === OrderStatusEnum.Canceled ? 'danger' : 'secondary'} />
      </Labels>

      <Date>{scheduled.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' })}</Date>
      {order.formattedArrivalWindow}
      {address && (
        <Address>
          <img src={MAP_MARKER} width="16" height="16" /> {address.street}
        </Address>
      )}
      <ButtonContainer>
        <ButtonLink to={orderURL(id)}>
          <Button fullWidth>View</Button>
        </ButtonLink>
        {order.accountCancelIntent?.reason !== AccountCancelIntentReason.PayToVacate && (
          <React.Fragment>
            <ButtonLink to={editable ? orderURL(id, 'reschedule') : '#'}>
              <Button fullWidth kind="secondary" disabled={!editable}>
                Reschedule
              </Button>
            </ButtonLink>
            <CancelLink to={orderURL(id, 'cancel')}>
              <Text.Button>Cancel</Text.Button>
            </CancelLink>
          </React.Fragment>
        )}
      </ButtonContainer>
    </Container>
  );
};

const InboundShipmentCardBody = ({ order, uncancellable }: ICardProps) => {
  if (uncancellable) {
    return (
      <ShipmentCardBodyContainer>
        <Text.Body>You'll receive an email from us as soon as we receive your items.</Text.Body>
      </ShipmentCardBodyContainer>
    );
  } else {
    return (
      <ShipmentCardBodyContainer>
        <Text.Body>
          Print your labels, and be sure to put them on the right box. Ship your items out by{' '}
          {formatDate(order.scheduled)}.
        </Text.Body>
        <br />
        <Text.Body>We'll let you know when they arrive.</Text.Body>
      </ShipmentCardBodyContainer>
    );
  }
};

export const InboundShipmentCard = ({ order, uncancellable }: ICardProps) => {
  const { status, id, shipped } = order;
  const orderNameStr = orderDisplayName(order, false)?.toUpperCase();

  return (
    <Container>
      <Labels>
        <Badge text={orderNameStr || order.type} kind="primary" />
        <Badge text={status} kind={status === OrderStatusEnum.Canceled ? 'danger' : 'secondary'} />
      </Labels>
      {status !== OrderStatusEnum.Canceled && <InboundShipmentCardBody order={order} uncancellable={uncancellable} />}

      <ButtonContainer>
        <FullWidthButtonLink to={orderURL(id)}>
          <Button fullWidth>View</Button>
        </FullWidthButtonLink>
        {!uncancellable && (
          <CancelLink to={shipped || status === OrderStatusEnum.Canceled ? '#' : orderURL(id, 'cancel')}>
            {shipped || status === OrderStatusEnum.Canceled ? (
              <DisabledButton>Cancel</DisabledButton>
            ) : (
              <Text.Button>Cancel</Text.Button>
            )}
          </CancelLink>
        )}
      </ButtonContainer>
    </Container>
  );
};

export const WaitlistCard = ({ order }: ICardProps) => {
  const { id, services, type, waitlistRequests } = order;
  const isPickupAndReturn = services && services.length > 1;
  const orderTypeStr = isPickupAndReturn ? 'PICKUP + RETURN' : type;
  const orderNameStr = orderDisplayName(order, false)?.toUpperCase();

  return (
    <Container>
      <Labels>
        <Badge text={orderNameStr || orderTypeStr} kind="primary" />
      </Labels>
      <WaitlistRequests waitlistRequests={waitlistRequests} />
      <Spacer height="2rem" />

      <ButtonContainer>
        <FullWidthButtonLink to={orderURL(id)}>
          <Button fullWidth>View & Edit Details</Button>
        </FullWidthButtonLink>
        {order.accountCancelIntent?.reason !== AccountCancelIntentReason.PayToVacate && !order.bookingPartner && (
          <CancelLink to={orderURL(id, 'cancel')}>
            <Text.Button>Cancel</Text.Button>
          </CancelLink>
        )}
      </ButtonContainer>
    </Container>
  );
};

export const OutboundShipmentCard = ({ order }: ICardProps) => {
  const { status, address, id, shipped } = order;
  const orderNameStr = orderDisplayName(order, false)?.toUpperCase();

  return (
    <Container>
      <Labels>
        <Badge text={orderNameStr || order.type} kind="primary" />
        <Badge text={status} kind={status === OrderStatusEnum.Canceled ? 'danger' : 'secondary'} />
      </Labels>
      {status !== OrderStatusEnum.Canceled && (
        <ShipmentCardBodyContainer>
          <Text.Body>
            {shipped
              ? 'Your items have shipped!'
              : "You'll receive an email with tracking information once your order has shipped."}
          </Text.Body>
        </ShipmentCardBodyContainer>
      )}

      {address && (
        <Address>
          <img src={MAP_MARKER} width="16" height="16" /> {address.street}
        </Address>
      )}
      <ButtonContainer>
        <FullWidthButtonLink to={orderURL(id)}>
          <Button fullWidth>View</Button>
        </FullWidthButtonLink>
        {order.accountCancelIntent?.reason !== AccountCancelIntentReason.PayToVacate && (
          <CancelLink to={shipped || status === OrderStatusEnum.Canceled ? '#' : orderURL(id, 'cancel')}>
            {shipped || status === OrderStatusEnum.Canceled ? (
              <DisabledButton>Cancel</DisabledButton>
            ) : (
              <Text.Button>Cancel</Text.Button>
            )}
          </CancelLink>
        )}
      </ButtonContainer>
    </Container>
  );
};

export const DisposalCard = ({ order }: ICardProps) => {
  const { id, status } = order;
  const unconfirmed = status === OrderStatusEnum.Pending;

  return (
    <Container>
      <Labels>
        <Badge text={'Disposal'} kind="primary" />
        <Badge
          text={unconfirmed ? 'Awaiting Signature' : status}
          kind={status === OrderStatusEnum.Canceled ? 'danger' : 'secondary'}
        />
      </Labels>
      <Spacer height="2rem" />

      <ButtonContainer>
        {unconfirmed && (
          <Box margin="0 0 32px">
            <Text.Body>
              You have documents that need to be signed before we can proceed with disposing your items.
            </Text.Body>
          </Box>
        )}
        <FullWidthButtonLink to={orderURL(id)}>
          <Button fullWidth>{unconfirmed ? 'View Details & Sign Documents' : 'View Details'}</Button>
        </FullWidthButtonLink>
        {unconfirmed && order.accountCancelIntent?.reason !== AccountCancelIntentReason.PayToVacate && (
          <CancelLink to={orderURL(id, 'cancel')}>
            <Text.Button>Cancel</Text.Button>
          </CancelLink>
        )}
      </ButtonContainer>
    </Container>
  );
};

interface ICardProps {
  order: Order | OrderDetailsOrder;
  editable?: boolean;
  uncancellable?: boolean;
}

export const Card = ({ order, editable, uncancellable }: ICardProps) => {
  const { type, serviceType, status, address, id, services } = order;

  if (type === OrderTypeEnum.Disposal) {
    return <DisposalCard order={order} />;
  }
  if (type === OrderTypeEnum.RetailDelivery) {
    return <RetailDeliveryCard order={order} editable={false} />;
  }
  if (serviceType === OrderServiceTypeEnum.Shipment && type === OrderTypeEnum.Pickup) {
    return <InboundShipmentCard order={order} uncancellable={uncancellable} />;
  }
  if (serviceType === OrderServiceTypeEnum.Shipment && type === OrderTypeEnum.Return) {
    return <OutboundShipmentCard order={order} />;
  }
  if (status === OrderStatusEnum.Pending && order.waitlistRequests.length) {
    return <WaitlistCard order={order} />;
  }

  const isPickupAndReturn = services && services.length > 1;
  const orderTypeStr = isPickupAndReturn ? 'PICKUP + RETURN' : type;
  const orderNameStr = orderDisplayName(order, false);
  const isFacility = order.serviceType === OrderServiceTypeEnum.Facility;
  const timelineDetailsMap = dateDetailsMap(order.nonTravelSourceAndSuccessors);

  return (
    <Container>
      <Labels>
        <Badge text={orderNameStr ? orderNameStr.toUpperCase() : orderTypeStr} kind="primary" />
        <Badge text={status} kind={status === OrderStatusEnum.Canceled ? 'danger' : 'secondary'} />
      </Labels>
      {order.multiDay ? (
        <MultiDayTimeline timelineDetailsMap={timelineDetailsMap} showArrivalTimes={true} sizing="large" />
      ) : (
        <>
          <Date>
            {DateTime.fromISO(order.scheduled, FROM_ISO_OPTIONS).toLocaleString({
              weekday: 'long',
              month: 'long',
              day: 'numeric',
            })}
          </Date>
          <>{order.formattedArrivalWindow}</>
        </>
      )}
      {address && (
        <Address>
          <img src={MAP_MARKER} width="16" height="16" />
          {isFacility ? <>{address.formatted}</> : <>{address.street}</>}
        </Address>
      )}
      {editable && (
        <ButtonContainer>
          <ButtonLink to={orderURL(id)}>
            <Button fullWidth>View & Edit Details</Button>
          </ButtonLink>
          {order.accountCancelIntent?.reason !== AccountCancelIntentReason.PayToVacate && !order.bookingPartner && (
            <React.Fragment>
              <ButtonLink to={orderURL(id, 'reschedule')}>
                <Button fullWidth kind="secondary">
                  Reschedule
                </Button>
              </ButtonLink>
              <CancelLink to={orderURL(id, 'cancel')}>
                <Text.Button>Cancel</Text.Button>
              </CancelLink>
            </React.Fragment>
          )}
        </ButtonContainer>
      )}
    </Container>
  );
};
